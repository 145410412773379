import { Box, Button, Grid, Link, Menu, MenuItem, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import ViewDocuments from "../../../../Components/Broker/Claim/ViewDocuments/ViewDocuments";
import AddNewRequest from "../../../../Components/Broker/Endorsment/AddNewRequestSP/AddNewRequest";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { ENDORSEMENT_SERVICES } from "../../../../Services/Endorsement/EndorsementService";
import { TEndorsementListView } from "../../../../Services/Types/Endorsement/TEndorsementListView";
import { useAppSelector } from "../../../../Store/hooks";
import {
  add_date_format,
  date_time_format,
  extractNumbersFromString,
  isIntegratedTpa,
} from "../../../../Supporting files/HelpingFunction";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
// import AddNewRequest from "../../../../Components/Broker/Endorsment/AddNewRequest/AddNewRequest";
import Rejected from "@mui/icons-material/Cancel";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import { toast } from "react-toastify";
import ConfirmationPopup from "../../../../Components/Broker/ConfirmationPopUp/ConfirmationPopup";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import { EMPLOYEE_SERVICES } from "../../../../Services/Employee/EmployeeService";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { RootState } from "../../../../Store/Store";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import "./../../../../Statuses.scss";
import MassUpdate from "../../../../Components/Common/MassUpdate/MassUpdate";

interface Props {
  raiseRequest?: boolean;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}

const Endosement: React.FC<Props> = ({
  raiseRequest,
  shortcutDisable,
  setShortcutDisable,
}) => {
  const { formatCurrency } = useFormatCurrency();
  const [addPolicyStatus, setAddPolicyStatus] = React.useState(false);
  const [addNewRequest, setAddNewRequest] = React.useState(false);
  const [viewDocuments, setViewDocuments] = React.useState(false);
  const [doc_data, setDoc_data] = useState([]);
  const navigate = useNavigate();
  const userType = useAppSelector(
    (state: any) => state.userDetailsSlice.userType
  );
  const [columns, setColumns] = useState<any>([]);
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const [rows, setRows] = useState<TEndorsementListView[]>([]);
  console.log("rows", rows);
  const user_type_dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [showLoader, setShowLoader] = useState(true);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setAddPermission] = useState<any>(null);
  const [csvPermission, setCsvPermission] = useState<any>(null);
  const [statusPermission, setStatusPermission] = useState<any>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  console.log("pageNumber", pageNumber);
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setselectedRow] = useState<any>({});
  const [massupdateopen, setmassupdateopen] = React.useState(false);
  const [selectedEndorsementId, setSelectEndorsementId] = useState<
    Array<{ id: number }>
  >([]);
  console.log("selectedEndorsementId", selectedEndorsementId);
  const [selectedEndorsementValue, setselectedEndorsementValue] = useState<any>(
    {}
  );
  
  console.log("rows",rows);
  const [statusWarningPopoup, setstatusWarningPopoup] =
    useState<boolean>(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDownloadAll = () => {
    // downloadCsv(rows);
    get_endorsement_list(totalRecords,0,"csv")
    handleClose();
  };

  const handleDownloadSelected = () => {
    let csvData;
    if (tableLimit > rows.length) {
      csvData = rows;
    } else {
      // csvData = rows.slice(
      //   pageNumber ? pageNumber * tableLimit : 0,
      //   (pageNumber + 1) * tableLimit
      // );
       csvData = rows.slice((pageNumber - 1) * tableLimit, pageNumber * tableLimit);
    }
    downloadCsv(csvData);
    handleClose();
  };
  let timeout: any;

  useEffect(() => {
    setAddPermission(
      BROKER?.Endorsement.add === undefined ? false : BROKER.Endorsement.add
    );
    setCsvPermission(
      BROKER?.Endorsement.csv === undefined ? false : BROKER.Endorsement.csv
    );
    setStatusPermission(
      BROKER?.Endorsement.status === undefined
        ? false
        : BROKER.Endorsement.status
    );
    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      get_endorsement_list();
      get_status(
        BROKER?.Endorsement.status === undefined
          ? false
          : BROKER.Endorsement.status
      );
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const get_status = (statusPer: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "endorsementId",
          headerName: "Endorsement ID",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  color: "#6b62dc",
                  textDecoration: "none",
                }}
                onClick={() => {
                  if (
                    row.endorsementFor === "Nominee Deletion" ||
                    row.endorsementFor === "Nominee Addition" ||
                    row.endorsementFor === "Member Deletion" ||
                    row.endorsementFor === "Member Addition"
                  ) {
                    navigate(
                      `${ALL_ROUTES.ENDORSEMENT_DETAILS_MEMBER_ADDITION}?id=${row._id}&policyId=${row.policyId}&memberId=${row.member_id}&tab=OVERVIEW`
                    );
                  } else {
                    navigate(
                      `${ALL_ROUTES.ENDORSEMENT_DETAILS}?id=${row._id}&tab=OVERVIEW`
                    );
                  }
                }}
              >
                {row.endorsementId}
              </Link>
            );
          },
        },
        {
          field: "clientId",
          headerName: "Client ID",
          flex: 4,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "clientName",
          headerName: "Client Name",
          flex: 8,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "memberId",
          headerName: "Member ID",
          flex: 3,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "insurer",
          headerName: "Insurer",
          flex: 2,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "tpaName",
          headerName: "TPA Name",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "memberName",
          headerName: "Member Name",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "policy",
          headerName: "Policy Number",
          flex: 1,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "type",
          headerName: "Endorsement Type",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "endorsementFor",
          headerName: "Endorsement Value",
          flex: 1,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "old",
          headerName: "Old Value",
          flex: 1,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            if (row.endorsementFor === "Date of Birth") {
              return <span>{add_date_format(row.old)}</span>;
            } else if (row.endorsementFor === "Salary") {
              return <span>{formatCurrency(Number(row?.old))}</span>;
            } else {
              return <span>{row?.old}</span>;
            }
          },
        },
        {
          field: "newvalue",
          headerName: "New Value",
          flex: 1,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            // if (isDateString(row?.new?.dob)) {
            //   return <span>{add_date_format(row?.new?.dob)}</span>;
            // }
            if (row.endorsementFor === "Personal Address") {
              return (
                <span>{`${row?.new?.street}, ${row?.new?.area}, ${row?.new?.city}, ${row?.new?.state},
               ${row?.new?.country} (${row?.new?.zip})`}</span>
              );
            } else if (row.endorsementFor === "Date of Birth") {
              return <span>{add_date_format(row?.new?.dob)}</span>;
            } else if (row.endorsementFor === "Salary") {
              return (
                <span>{`${formatCurrency(
                  Number(
                    extractNumbersFromString(row?.new.salary.split("/")[0])
                  )
                )} ${" "}(${row?.new.salary.split("/")[1] ?? ""})`}</span>
              );
            } else {
              return renderEndorsementValue(row, "new");
            }
          },
        },
        {
          field: "status",
          headerName: "Endorsement Status",
          minWidth: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 54 ? (
                    <Requested id={`_${row.status.toString()}`} />
                  ) : row.status === 55 ? (
                    <SentToTPA id={`_${row.status.toString()}`} />
                  ) : row.status === 56 ? (
                    <Required id={`_${row.status.toString()}`} />
                  ) : row.status === 57 ? (
                    <Approved id={`_${row.status.toString()}`} />
                  ) : row.status === 58 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 59 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 71 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 72 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!statusPer}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "supportingdocuments",
          headerName: "Supporting Documents",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link sx={{ textDecoration: "none" }}>
                <span
                  style={{
                    color: "#000000",
                    textDecoration: "none",
                    marginRight: "10px",
                  }}
                >
                  {row.docCount}
                </span>
                <img
                  src="images/visibility-icon.svg"
                  alt=""
                  style={{
                    zIndex: 99,
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setViewDocuments(true);
                    setDoc_data(row?.attachments);
                  }}
                />
              </Link>
            );
          },
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.createdAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.updatedAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_endorsement_list();
    };
    const onError = (err: any) => {
      toast.warn(err.response.data.errors[0].message);
    };
    ENDORSEMENT_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  function renderEndorsementValue(row: any, data: string) {
    switch (row?.endorsementFor) {
      case "Name":
        return (
          <span>{row?.[data]?.firstName + " " + row?.[data]?.lastName}</span>
        );
      case "Personal Address":
        return <span>{row?.[data]?.street}</span>;
      case "Contact Number":
        return <span>{row?.[data]?.contactNumber}</span>;
      case "Gender":
        return <span>{row?.[data]?.gender}</span>;
      case "Department":
        return <span>{row?.[data]?.department}</span>;
      case "Designation":
        return <span>{row?.[data]?.designation}</span>;
      case "salary":
        return (
          <span>{formatCurrency(Number(row[data]?.salary.split("/")[0]))}</span>
        );
      case "Salary":
        return (
          <span>{formatCurrency(Number(row[data]?.salary.split("/")[0]))}</span>
        );
      case "Salary":
        return (
          <span>{formatCurrency(Number(row[data]?.salary.split("/")[0]))}</span>
        );
      case "Salary Grade":
        return <span>{row?.[data]?.salary_grade}</span>;
      case "Email":
        return <span>{row?.[data]?.email}</span>; // Assuming you meant "Email" here
      default:
        return null; // or some default value or message
    }
  }

  const get_endorsement_list = (limit?: number, skip?: number,csv?:any) => {
    const onSuccess = (res: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      const allData = res.data.data.employee;
      console.log("allData",res.data.data)
      const data = res.data.data.employee.map((el: any) => ({
        ...el,
        id: el._id,
      }));
      if(!csv){
        setRows(data);
        setTotalRecords(res.data.data.countData);
      }
      if(csv){

        downloadCsv(allData);
      }
     
    };
    const onError = (err: any) => {
      console.log("errr", err);
      setShowLoader(false);
    };
    let api_url = URL_CONSTANTS.get_endorsement_list_view(
      userType?.toLowerCase(),
      limit ?? 25,
      skip ?? 0,
      searchBarFilter
    );
    ENDORSEMENT_SERVICES.get_endorsement_list(api_url, onSuccess, onError);
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      if (searchBarFilter) {
        get_endorsement_list();
      }
    };
    timeoutId = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === setViewDocuments) {
      return setViewDocuments(value);
    } else if (attrName[0] === "updatestatus") {
      if(attrName[1].status == 76) return toast.warning("HR Rejected The Endorsment")
      if ((value == 75 || value == 76)) {
        return toast.warning("This status is Automated");
      }
      if(attrName[1].status != 54){
        if (
          (attrName[1].endorsementFor === "Member Addition" ||
            attrName[1].endorsementFor === "Member Deletion") &&
          isIntegratedTpa(attrName[1].tpaName)
        ) {
          return toast.warning("This status is Automated");
        }
        if (value === 54) return toast.warning("This status is Automated");
        if (value === 58) {
          setstatusWarningPopoup(true);
          setselectedRow(attrName[1]);
          setselectedEndorsementValue(value);
          return;
        }
        if (value === 57) {
          if (
            attrName[1].endorsementFor === "Member Addition" ||
            attrName[1].endorsementFor === "Member Deletion" ||
            attrName[1].endorsementFor === "Nominee Addition" ||
            attrName[1].endorsementFor === "Nominee Deletion"
          ) {
            update_status(value, attrName[1]._id);
            return;
          }
          const onSuccess = (res: any) => {
            update_status(value, attrName[1]._id);
          };
          const onError = (err: any) => {
            console.log("errr");
          };
  
          let {
            dob,
            email,
            firstName,
            gender,
            lastName,
            contactNumber,
            ...remainData
          } = attrName[1]?.new;
          let newVal =
            attrName[1].update_key === "Personal Address"
              ? remainData
              : attrName[1].endorsementFor === "Designation"
              ? attrName[1].new.designation
              : attrName[1].endorsementFor === "Salary Grade"
              ? attrName[1].new.salary_grade
              : attrName[1].endorsementFor === "Date of Birth"
              ? attrName[1].new.dob
              : attrName[1].endorsementFor === "Salary"
              ? attrName[1].new.salary
              : attrName[1].endorsementFor === "Department"
              ? attrName[1].new.department
              : attrName[1].endorsementFor === "Name"
              ? attrName[1].new.firstName + "/" + attrName[1].new.lastName
              : // attrName[1].new.firstName ||
                // attrName[1].new.lastName ||
                attrName[1].new.gender ||
                attrName[1].new.email ||
                (attrName[1].new.contactNumber > 0 &&
                  attrName[1].new.contactNumber);
          EMPLOYEE_SERVICES.update_detail_view_individual_edit_employee(
            { key: attrName[1].update_key, value: newVal },
            onSuccess,
            onError,
            attrName[1].member_id,
            userType.toLowerCase()
          );
        } else {
          update_status(value, attrName[1]._id);
        }
      }else{
        return toast.warning("HR Approval Pending.");
      }
   
    } else if (attrName === "selected_data") {
      setSelectEndorsementId(value);
    } else if (attrName === "massupdateopen") {
      setmassupdateopen(false);
    } else if (attrName === setAddNewRequest) {
      setAddNewRequest(false);
    } else {
      //attrName(value);
    }
  };

  const downloadCsv = (csvData: any) => {
  
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "endorsement.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = csvData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          "Endorsement ID": item?.endorsementId,
          "Client ID": item?.clientId,
          "Client Name": item?.clientName,
          "Member ID": item?.memberId,
          Insurer: item?.insurer,
          "TPA Name": item?.tpaName,
          "Member Name": item?.memberName,
          "Policy Number": item?.policy,
          "Endorsement Type": item?.type,
          "Policy Type": item?.policyType,
          "Endorsement Value": item?.endorsementFor,
          "Old Value": item?.old,
          "New Value": item.new
            ? Object.values(item.new)
                .filter((value) => value !== "" && value !== 0)
                .join(" ")
            : "",
          "Endorsement Status": statusObj?.value,
          "Created At": date_time_format(
            item?.createdAt,
            user_type_dateFormat,
            tz
          ),
          "Updated At": date_time_format(
            item?.updatedAt,
            user_type_dateFormat,
            tz
          ),
        };
      }
      return item;
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userType.toLowerCase(),
      "endorsement"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}

      <ConfirmationPopup
        statusWarningPopoup={statusWarningPopoup}
        setstatusWarningPopoup={setstatusWarningPopoup}
        update_status={() => {
          update_status(selectedEndorsementValue, selectedRow?._id);
        }}
        title={`Rejecting the endorsement ${selectedRow?.endorsementId} will halt the change of ${selectedRow?.endorsementFor} for ${selectedRow?.memberId}, ${selectedRow?.memberName} &  ${selectedRow?.policyNumber}`}
      />
      <ViewDocuments
        attrName={setViewDocuments}
        open_status={viewDocuments}
        value_update={updateMasterState}
        data={doc_data}
      />
      <Grid container spacing={2} className="claimAssistant">
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-3">Endorsement</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid item xs={6} className="text-right">
          <UGDateRangePicker
            minDate={new Date()}
            maxDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />

          {addPermission && selectedEndorsementId.length > 1 && (
            <Link
              underline="none"
              className="blueBtn addfile"
              onClick={() => {
                setmassupdateopen(true);
                if (!shortcutDisable && setShortcutDisable) {
                  setShortcutDisable(true);
                }
                // navigate(ALL_ROUTES.ENDORSEMENT_BULK_UPDATE_STATUS)
              }}
            >
              Mass Update Of Endorsement
            </Link>
          )}
          {csvPermission && rows?.length > 0 && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}

          {addPermission && (
            <Link
              underline="none"
              className="blueBtn addfile"
              onClick={() => {
                setAddNewRequest(true);
                if (!shortcutDisable && setShortcutDisable) {
                  setShortcutDisable(true);
                }
              }}
            >
              Raise New Request
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          <UGTable
            header_data={columns}
            data={rows}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.ENDORSEMENT}
            refetch_data={get_endorsement_list}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
            // setPageNumberData={setPageNumber}
            disableCheckboxKey={"tpaType"}
            disableCheckboxValue={"INTEGRATED"}
          />
        </Grid>
        <Modal open={addPolicyStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddPolicyStatus(false);
                  }}
                />
                <h5 className="popup-heading">Uploading file</h5>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} className="mb-5">
                <Link className="md-link" underline="none">
                  67%... donot close or switch tab while it’s uploading
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                className="ctaBtn"
                style={{ textAlign: "center" }}
              >
                <Button variant="contained" className="submitBtn">
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <AddNewRequest
          attrName={setAddNewRequest}
          open_status={addNewRequest}
          value_update={updateMasterState}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
          callApi={get_endorsement_list}
        />
        <MassUpdate
          open_status={massupdateopen}
          value_update={updateMasterState}
          selected_id={selectedEndorsementId}
          update_Api_url={
            ENDORSEMENT_SERVICES.updateEndorsementMassmodification
          }
          // update_Api_url={""}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
          forComponent="Endorsement"
        />
      </Grid>
    </>
  );
};

export default Endosement;

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { bulkUploadSlice } from "../../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";
import { RootState } from "../../../Store/Store";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { date_time_format } from "../../../Supporting files/HelpingFunction";
import UGTable from "../../../Supporting files/UGTable/UGTable";
import "../BulkUpload.scss";
import NewLoader from "../../NewLoader/NewLoader";

function BulkUploadList() {
  const user_type_dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const map_field_api_response = useAppSelector(
    (state) => state.bulkUpload.map_field_api_response
  );
  const [listData, setListData] = React.useState<any[]>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [loader, setloader] = React.useState<boolean>(true);
  console.log("listData",listData)
  console.log("tableData",tableData)

  useEffect(() => {
//     // manipulate the data as per frontend requiredment from the redux
//     let data: Array<string> = [];
//     let dataValuesArray: any = [];
//     let newArr: any = [];
//     if (
//       map_field_api_response?.errorData &&
//       map_field_api_response.errorData.length > 0
//     ) {
//       newArr = map_field_api_response.errorData.map(
//         ({ registerSpocId, ...rest }) => rest
//       );
//       data = Object.keys(newArr[0]);
//       dataValuesArray = newArr.map((value: any) => Object.values(value));
//     }
//     let isEmployee = false
//     let newData = map_field_api_response.errorData.map(
//       (data: any, index: number) => {
// if(data["Member Type"] === "Employee"){
//   isEmployee = true
// }
//         return { ...data, id: index + 1 };
//       }
//     );
//     data = data.filter(item=>(item !== "attachedPrimaryEmployee" && item !== "Id"))
//     if(isEmployee){
//        data = data.filter(item => item !== "Primary Employee");

//     }


// Manipulate data as per frontend requirements from the Redux store
let data:any = [];
let dataValuesArray = [];
let newArr = [];

if (map_field_api_response?.errorData?.length) {
    newArr = map_field_api_response.errorData.map(({ registerSpocId, ...rest }) => rest);
    data = Object.keys(newArr[0]);
    dataValuesArray = newArr.map(Object.values);
}

let isEmployee = map_field_api_response.errorData.some(data => data["Member Type"] === "Employee");

const newData = map_field_api_response.errorData.map((data, index) => ({
    ...data,
    id: index + 1
}));

data = data.filter((item:any) => !["attachedPrimaryEmployee", "Id"].includes(item));

if (isEmployee) {
    data = data.filter((item:any) => item !== "Primary Employee");
}

    console.log("dddd",data)
    let header = data.map((val:any) => ({
      field: val,
      headerName: val,
      width: 170,
      check_status: true,
    }));
    setloader(false);
    let arr: any[] = [];
    header.forEach((data:any) => {
      if (data.field === "Date Of Birth") {
        arr.push({
          ...data,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row["Date Of Birth"],
                  user_type_dateFormat,
                  tz
                ) === "Invalid date"
                  ? "--"
                  : date_time_format(
                      row["Date Of Birth"],
                      user_type_dateFormat,
                      tz
                    )}
              </span>
            );
          },
        });
      }else if (data.field === "Date Of Joining") {
        arr.push({
          ...data,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row["Date Of Joining"],
                  user_type_dateFormat,
                  tz
                ) === "Invalid date"
                  ? "--"
                  : date_time_format(
                      row["Date Of Joining"],
                      user_type_dateFormat,
                      tz
                    )}
              </span>
            );
          },
        });
      } else {
        arr.push(data);
      }
    });
    const updatedHeaderData = arr.map((obj:any) => {
      const updatedObj = { ...obj };
      Object.keys(updatedObj).forEach((key) => {
        if (updatedObj[key] === "errorMessage") {
          updatedObj[key] = "Error Message";
        }
      });
      return updatedObj;
    });
  //   const updatedData = data.map(item => {
  //     const { errorMessage, ...rest } = item; // Destructure errorMessage and the rest of the keys
  //     return { 
  //         ...rest, 
  //         "Error Message": errorMessage // Replace key "errorMessage" with "Error Message"
  //     };
  // });
  const updatedTableData = newData.map((item:any)=>{
    const{errorMessage,...rest} = item;
    return {
      ...rest,
      "Error Message" : errorMessage
    }
  })
    setListData(updatedHeaderData);//arr
    setTableData(updatedTableData);//newData
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  return (
    <>
      {loader && <NewLoader />}
      <Box className="BulklayoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-2);
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadPageStatus(
                    "BULK_UPLOAD_PAGE"
                  )
                );
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3> Import Summary</h3>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12} className="innerSection pl-10 pr-10">
            <Box>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="mapfields_instructions mb-4"
                  display={"flex"}
                >
                  <Grid container spacing={3} padding="0">
                    <Grid xs={8}>
                      <ul className="recordViewList">
                        <li className="black_li">
                          Total records in file:{" "}
                          <span>
                            {map_field_api_response.overWriteCount +
                              map_field_api_response.skipCount +
                              map_field_api_response.successData}
                          </span>
                        </li>
                        <li>
                          Added:{" "}
                          <span>{map_field_api_response.successData}</span>
                        </li>
                        <li>
                          Updated:{" "}
                          <span>{map_field_api_response.overWriteCount}</span>
                        </li>
                        <li>
                          Skipped:{" "}
                          <span>{map_field_api_response.skipCount}</span>
                        </li>
                      </ul>
                    </Grid>
                    {map_field_api_response?.errorData &&
                    map_field_api_response.errorData.length > 0 ? (
                      <Grid xs={4} textAlign="right">
                        <CSVLink
                          data={map_field_api_response.errorData}
                          filename={`errorData${new Date()}`}
                        >
                          <Link underline="none" className="blueBtn download">
                            Export
                          </Link>
                        </CSVLink>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                {map_field_api_response?.errorData &&
                    map_field_api_response.errorData.length > 0 &&
                <Grid xs={12} padding="0">
                  <UGTable
                    header_data={listData}
                    data={tableData}
                    value_update={updateMasterState}
                    attrName={"UGTable"}
                    paginationMode={false}
                    checkboxSelection={false}
                    hideThreeDot
                  />
                </Grid>
}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="contained"
              className="submitBtn"
              onClick={() => {
                navigate(-2);
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadPageStatus(
                    "BULK_UPLOAD_PAGE"
                  )
                );
              }}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BulkUploadList;

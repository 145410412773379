import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { ORGANISATION_SERVICES } from "../../../../../../Services/customModule/organisationServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import "../../NewSettings.scss";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

function OrganisationDetails() {
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [editCompanyPermission,setEditCompanyPermission] = useState<boolean>(false);
  const userDetails = useAppSelector((state) => state.userDetailsSlice);
  const navigate = useNavigate();
  const [organisationDetails, setorganisationDetails] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    setEditCompanyPermission( BROKER?.CompanyDetails?.edit === undefined ? false : BROKER?.CompanyDetails?.edit)
  }, []);

  const organisation_details = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setorganisationDetails(res.data.data[0]);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ORGANISATION_SERVICES.organisation_details(
      onSuccess,
      onError,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  useEffect(() => {
    organisation_details();
  }, []);

  console.log(userDetails.parentsId && editCompanyPermission, userDetails.parentsId , editCompanyPermission)

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="settingMenuWrapper">
          <Grid container spacing={3}>
            <Grid xs={4} md={4} lg={4}>
              <div className="logosection pt-13">
                <img alt="" className="companylogo"
                  src={organisationDetails?.logo}
                  style={{ width: '100%', objectFit: 'contain' }}
                />
                <h5>{organisationDetails.orgnizationName}</h5>
              </div>

              <div className="logosection">
                <h6>Organasiation Details</h6>
                <div className="Companydetail">
                  <div className="text-left">
                    <p>Industry</p>
                    <h6 style={{ textAlign: "left" }}>
                      {organisationDetails.industry}
                    </h6>
                  </div>
                  <div className="text-right">
                    <p>Sub-Industry</p>
                    <h6 style={{ textAlign: "right" }}>
                      {organisationDetails.subIndustry}
                    </h6>
                  </div>
                </div>
                <div className="Companydetail">
                  <div className="text-left">
                    <p>Website URL</p>
                    <h6>{organisationDetails.url}</h6>
                  </div>
                  <div className="text-right">
                    <p>Tax ID</p>
                    <h6>{organisationDetails.taxId}</h6>
                  </div>
                </div>
                <div className="Companydetail">
                  <div className="text-left">
                    <p>Fiscal Year</p>
                    <h6>{organisationDetails.fiscalyear}</h6>
                  </div>
                  <div className="text-right">
                    <p>Company ID</p>
                    <h6>{organisationDetails.companyId}</h6>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={8} md={8} lg={8}>
              <div className="right_section">
                <Grid container spacing={3}>
                  <Grid xs={12} className="text-left">
                    <h5>
                      Primary Contact
                      <span>
                        You can use the following details to contact regarding
                        any query about this application.
                      </span>
                    </h5>
                  </Grid>
                  <Grid xs={4} className="text-left">
                    <p>Name</p>
                    <h6>{organisationDetails.name}</h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Designation</p>
                    <h6>{organisationDetails.designation}</h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Email ID</p>
                    <h6>{organisationDetails?.email}</h6>
                  </Grid>
                </Grid>
              </div>
              <div className="right_section">
                <Grid container spacing={3}>
                  <Grid xs={12} className="text-left">
                    <h5>
                      Local Information
                      <span>This is your locale information.</span>
                    </h5>
                  </Grid>
                  <Grid xs={3} className="text-left">
                    <p>Country/Region</p>
                    <h6>{organisationDetails.country}</h6>
                  </Grid>
                  <Grid xs={5}>
                    <p>Time Zone</p>
                    <h6>{organisationDetails.timeZone}</h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Currency (Base Currency)</p>
                    <h6>{organisationDetails.currency}</h6>
                  </Grid>
                  <Grid xs={3}>
                    <p>Language</p>
                    <h6>English</h6>
                  </Grid>
                  <Grid xs={5}>
                    <p>Date Format</p>
                    <h6>{organisationDetails.dateFormat}</h6>
                  </Grid>
                  {/* <Grid xs={4}>
                <p>Report Basis</p>
                <h6>{organisationDetails.reportBasis}</h6>
              </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <div className="address_section">
                <Grid container spacing={3}>
                  <Grid xs={12} className="text-left">
                    <h5>
                      Address Details
                      <span>This is your organisation registered address.</span>
                    </h5>
                  </Grid>
                  <Grid xs={4} className="text-left">
                    <p>Business Location</p>
                    <h6>
                      {organisationDetails.state},{organisationDetails.city}
                    </h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Address</p>
                    <h6>
                      {organisationDetails.address1},
                      {organisationDetails.address2}
                    </h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Pincode</p>
                    <h6>{organisationDetails.pinCode}</h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Phone Number</p>
                    <h6>{organisationDetails.phoneNumber}</h6>
                  </Grid>
                  <Grid xs={4}>
                    <p>Mobile Number</p>
                    <h6>{organisationDetails.mobileNumber}</h6>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* Footer Section */}

          {/* {!userDetails.parentsId && (
            <Grid container spacing={3} className="footerSection">
              <Grid xs={12} className="ctaBtn">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    navigate(ALL_ROUTES.EDIT_ORG_DETAILS);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          )} */}

          {editCompanyPermission? (
            <Grid container spacing={3} className="footerSection">
              <Grid xs={12} className="ctaBtn">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    navigate(ALL_ROUTES.EDIT_ORG_DETAILS);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          ): (<></>) }
        </Box>
      )}
    </>
  );
}

export default OrganisationDetails;

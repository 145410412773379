import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import {
  get_city_list_via_name,
  get_client_list,
  get_country_list,
  get_state_list,
  validateEmail,
  validateMobileNumber,
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../../formLayout.scss";
import ChangePassword from "../../../../../Desktop/Broker/Settings/GeneralSettings/PersonalSettings/ChangePassword/ChangePassword";
import { useAppSelector } from "../../../../../../Store/hooks";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { PERSONAL_SETTINGS_SERVICES } from "../../../../../../Services/customModule/personalSettingsServices";
import { toast } from "react-toastify";
import { da } from "date-fns/locale";

function EditCreateUsers() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const navigate = useNavigate();
  const [usersRole, setUsersRole] = useState<any>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("userInformation");
  const [subSection, setSubSection] = useState<string>("basics");
  const [id, setId] = useState<string>("");
  const [searchList, setSearchList] = useState<boolean>(false);
  const [priorityStatus, setPriorityStatus] = useState(13);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [changePassword, setChangePassword] = useState(false);
  const [contacts, setContacts] = useState<Array<{
    checkbox_status: boolean;
    id: number;
    name: string;
  }>>([
    {
      checkbox_status: false,
      id: 0,
      name: "All Clients",
    },
    {
      checkbox_status: false,
      id: 1,
      name: "EV0065 - Vikas Saini",
    },
    {
      checkbox_status: false,
      id: 2,
      name: "EV007 - Umesh Gemini",
    },
    {
      checkbox_status: false,
      id: 3,
      name: "EV0016 - Kartike Kakkar",
    },
    {
      checkbox_status: false,
      id: 4,
      name: "EV0065 - Vikas Saini",
    },
    {
      checkbox_status: false,
      id: 5,
      name: "EV007 - Umesh Gemini",
    },
    {
      checkbox_status: false,
      id: 6,
      name: "EV0016 - Kartike Kakkar",
    },
    {
      checkbox_status: false,
      id: 7,
      name: "EV0065 - Vikas Saini",
    },
    {
      checkbox_status: false,
      id: 8,
      name: "EV007 - Umesh Gemini",
    },
    {
      checkbox_status: false,
      id: 9,
      name: "EV0016 - Kartike Kakkar",
    },
  ]);
  const [editUser, setEditUser] = useState<{
    name: string;
    email: string;
    gender: string;
    dob: string;
    building: string;
    area: string;
    country: { label: string; id: string };
    state: { label: string; id: string };
    city: { label: string; id: string };
    mobileNumber: string;
    pinCode: string;
    role: string;

    branch: string;
    department: string;
    clientName: string;
    client: { label: string; id: string };
    warnName: boolean;
    warnMobile: boolean;
    warnEmail: boolean;
    warnRolename: boolean;
    warnBranch: boolean;
    warnDepartment: boolean;
    warnGender:boolean,
    warnDob:boolean,
    warnStreet:boolean,
    warnCountry : boolean,
    warnState : boolean,
    warnCity:boolean,
    warnPincode:boolean
  }>({
    name: "",
    email: "",
    gender: "Male",
    dob: "",
    building: "",
    area: "",
    country: { label: "", id: "" },
    state: { label: "", id: "" },
    city: { label: "", id: "" },
    mobileNumber: "",
    pinCode: "",
    role: "",
   
    branch: "",
    department: "",
    clientName: "",
    client: { label: "", id: "" },
    warnName: false,
    warnMobile: false,
    warnEmail: false,
    warnRolename: false,
    warnBranch: false,
    warnDepartment: false,
    warnGender:false,
    warnDob:false,
    warnStreet:false,
    warnCountry:false,
    warnCity:false,
    warnState:false,
    warnPincode:false

  });
  const [userStatus,setUserStatus] = useState<number>();
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const [department_data, setDepartment_data] = useState<Array<{
    id: string;
    label: string;
  }>>([]);
  const [branch_data, setBranch_data] = useState<Array<{
    id: string;
    label: string;
  }>>([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [clientListData, setClientListData] = useState<Array<{
    checkbox_status: boolean;
    label: string;
    id: string;
  }>>([]);


  // for scrolling
  const userInformation = useRef(null);
  const basics = useRef(null);
  const clientInfo = useRef(null);
  const personalDetails = useRef(null);
  const addressDetails = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [userEditPermission, setuserEditPermission] = useState(false);

  useEffect(() => {
    client_list();
    user_role_data();
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    get_status();
    setShowLoader(true);
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    user_data(id ?? "");
    get_Dropdown_branch_list(URL_CONSTANTS.get_branch_dropdown_list);
    get_Dropdown_branch_list(URL_CONSTANTS.get_department_dropdown_list);
    setuserEditPermission(BROKER?.Users?.edit === undefined ? false : BROKER?.Users?.edit);
  }, []);

  useEffect(() => {
    get_state_list(editUser.country, (cb: any) => {
      setStateData(cb);
    });

    get_city_list_via_name(editUser.country, editUser.state, (cb: any) => {
      setCityData(cb);
    });
  }, [editUser.country, editUser.state]);


  const get_Dropdown_branch_list = (url: string) => {
    const onSuccess = (res: any) => {
      if (url === URL_CONSTANTS.get_branch_dropdown_list) {
        let BranchData = res.data.data.map((data: any) => {
          return { label: data.id, value: data.label };
        });

        setBranch_data(BranchData);
      } else if (url === URL_CONSTANTS.get_department_dropdown_list) {
        let DepData = res.data.data.map((data: any) => {
          return { label: data.id, value: data.label };
        });
        setDepartment_data(DepData);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_branch_department_dropdown_list(
      url,
      onSuccess,
      onError
    );
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const searchHandler = (event: any) => {
    let searcjQery = event.target.value.toLowerCase(),
      displayedContacts = clientListData.filter((el) => {
        let searchValue = el.label.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    setClientListData(displayedContacts);
    setSearchList(true);
  };

  const selectClientsFromList = (index: number, check_status: boolean) => {
    let data = clientListData;

    data[index].checkbox_status = check_status;

    setClientListData(data);
  };

  const user_data = (id: string) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let data = res.data.data[0];
      setUserStatus(data?.status);
      setEditUser({
        name: data?.name || "",
        email: data?.email || "",
        gender: data?.gender,
        dob: data?.dob || "",
    
        building: data?.addressDetails?.address1,
        area: data?.addressDetails?.address2,
        country: {
          label: data?.addressDetails?.country,
          id: data?.addressDetails?.country,
        },
        state: {
          label: data?.addressDetails?.state,
          id: data?.addressDetails?.state,
        },
        city: { label: data?.addressDetails?.city, id: data?.addressDetails?.city },
        mobileNumber: data?.addressDetails?.mobileNumber,
        pinCode: data?.addressDetails?.pinCode,
        role: data?.role,
        branch: data?.branch,
        department: data?.department,
        clientName: data?.clientName,
        client: { label: "", id: "" },
        warnName: false,
        warnMobile: false,
        warnEmail: false,
        warnRolename: false,
        warnBranch: false,
        warnDepartment: false,
        warnGender:false,
        warnDob:false,
        warnStreet:false,
    warnCountry:false,
    warnCity:false,
    warnState:false,
    warnPincode:false
      });
      setClientListData(res.data.data[0].clientName);
      setPriorityStatus(res.data.data[0].status);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.user_data_by_id(id, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "editUser") {
      if (key === "Department") {
        setEditUser({ ...editUser, department: value.value });
      } else {
        setEditUser({
          ...editUser,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      }
    } else if (attrName === setPriorityStatus) {
      setPriorityStatus(value);

      update_status(value, id);
    } else if (attrName === setPriorityStatus) {
      const onSuccess = (res: any) => {
      };
      const onError = (err: any) => {
        console.log("ppp", err);
      };
      //  attrName(value);

      typeof attrName === 'function' && attrName(value);
      let params = {
        status: value,
      };
      ACCESS_LEVEL_SERVICES.user_change_status(params, onSuccess, onError, id);
    }
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const client_list = () => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data?.forEach((e: any) => {
        data.push({
          ...e,
          checkbox_status: false,
        });
      });
      setClientListData(data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    get_client_list(userDetails?.userType?.toLowerCase(), onSuccess, onError);
  };

  // user role data
  const user_role_data = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.id, value: data.label };
      });
      setUsersRole(statusData);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ACCESS_LEVEL_SERVICES.get_user_role_data("", onSuccess, onError);
  };
console.log("edituser",editUser)
  const   detail_form_submit = () => {
    
    if (disableSave) return;
    if (!userEditPermission) return toast.error("Update permission not allowed");
    let data: any = editUser;
    data.warnName = editUser?.name?.length === 0 ? true : false;
    data.warnCountry =  editUser?.country?.label.length === 0 ? true :false;
    data.warnEmail = !validateEmail(editUser.email);
    data.warnRolename = editUser?.role === null ? true : false;
    data.warnBranch = editUser.branch === null ? true : false;
    data.warnDepartment = editUser.department === null ? true : false;
    data.warnMobile = !validateMobileNumber(editUser.mobileNumber);
    data.warnPincode = !editUser?.pinCode? true : false
    data.warnGender = editUser?.gender?.length === 0 ? true :false
    data.warnDob = editUser?.dob ? false :true;
   data.warnStreet = editUser?.building.length === 0 ? true :false;

   data.warnState = editUser?.state?.label.length === 0 ? true :false;
    data.warnCity = editUser?.city?.label.length === 0 ? true : false;
   


    setEditUser({ ...data });
    if (
      data.warnName === false &&
      data.warnEmail === false &&
      data.warnRolename === false &&
      data.warnDepartment === false &&
      data.warnMobile === false && 
      data.warnGender === false && 
      data.warnDob === false && 
      data.warnStreet === false && 
      data.warnCountry === false &&
      data.warnCity === false && 
      data.warnState === false 
      
    ) {
      let mainData: any = editUser;
      let params = {
        _id: id,
        name: editUser.name,
        email: editUser.email,
        role: editUser.role,
        branch: editUser.branch,
        Department: editUser.department,
        gender: mainData.gender.value ? mainData.gender.value : mainData.gender,
        dob: editUser.dob,
        client: "All",
        clientName: [],
        addressDetails: {
          state: editUser.state?.label,
          country: editUser.country?.label,
          city: editUser.city?.label,
          pinCode: editUser.pinCode,
          mobileNumber: editUser.mobileNumber,
          address1: editUser.building,
          address2: editUser.area,
        },
      };
      const onSuccess = (res: any) => {
        navigate(-1);
      };
      const onError = (err: any) => {
        setDisableSave(false);
        toast.error(err.response.data.errors[0].message)
        console.log("false", err);
      };
      setDisableSave(true);
      ACCESS_LEVEL_SERVICES.user_update_data_by_id(params, onSuccess, onError);
    }
  };

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="layoutWrapper">
        <ChangePassword
          attrName={setChangePassword}
          open_status={changePassword}
          value_update={updateMasterState}
          email={editUser.email}
          update_api_url={userDetails.parentsId ? PERSONAL_SETTINGS_SERVICES.update_password : ACCESS_LEVEL_SERVICES.update_password_broker}
        />

        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3>Edit - {editUser.name}</h3>
            </div>
          </Grid>
          <Grid
            xs={6}
            textAlign={"right"}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              className="updatePassword mr-4"
              onClick={() => {
                if (!userEditPermission) return toast.error("Update permission not allowed");
                setChangePassword(true);
              }}
            >
              <AdminPanelSettingsIcon /> Update Password
            </Button>
            {/* Status Dropdown */}
            <div id={`_${priorityStatus.toString()}`}>
              <div
                className="status_dropdown"
                id={`_${priorityStatus.toString()}`}
              >
                {priorityStatus === 13 ? (
                  <CheckCircleIcon id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 14 ? (
                  <HighlightOffIcon id={`_${priorityStatus.toString()}`} />
                ) : null}

                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                  disabled={!userEditPermission}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection">
                <Link
                  className={
                    sectionStatus === "userInformation"
                      ? "active sectionLink userInformation"
                      : "sectionLink userInformation"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(userInformation);
                    SetSectionStatus("userInformation");
                  }}
                >
                  <span> User Information</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "basics"
                          ? "inner_link active basics"
                          : "inner_link  basics"
                      }
                      onClick={() => {
                        handleClicks(subSection);
                        scrollToSection(basics);
                        setSubSection("basics");
                      }}
                    >
                      Basic Info
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        subSection === "personalDetails"
                          ? "inner_link active personalDetails"
                          : "inner_link  personalDetails"
                      }
                      onClick={() => {
                        handleClicks(subSection);
                        scrollToSection(personalDetails);
                        setSubSection("personalDetails");
                      }}
                    >
                      Personal Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        subSection === "addressDetails"
                          ? "inner_link active addressDetails"
                          : "inner_link  addressDetails"
                      }
                      onClick={() => {
                        handleClicks(subSection);
                        scrollToSection(addressDetails);
                        setSubSection("addressDetails");
                      }}
                    >
                      Address Details
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid xs className="rightSection">
                <Box className="detailSection" ref={userInformation}>
                  <div
                    onMouseEnter={() => {
                      setSubSection("basics");
                    }}
                    ref={basics}
                  >
                    <div className="sectionTitle">
                      <h4>User Information</h4>
                    </div>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Basic Info</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          required
                          validation_type="email"
                          class_name="inputField"
                          title={"User ID / E-mail"}
                          value={editUser?.email}
                          attrName={["editUser", "email"]}
                          value_update={updateMasterState}
                          warn_status={editUser?.warnEmail}
                          disabled
                        />
                      </Grid>

                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <SelectDropdown
                          required
                          class_name="inputField"
                          title="Role"
                          value={editUser.role}
                          attrName={["editUser", "role"]}
                          value_update={updateMasterState}
                          dropdown_data={usersRole}
                          warn_status={editUser?.warnRolename}
                          error_messg="Please select Role"
                        />
                      </Grid>
                      <Grid sm={4} md={4} lg={3} xl={2}>
                        <SelectDropdown
                          required
                          class_name="inputField"
                          title="Branch"
                          value={editUser.branch}
                          attrName={["editUser", "branch"]}
                          value_update={updateMasterState}
                          dropdown_data={branch_data}
                          warn_status={editUser.warnBranch}
                          error_messg="Please select Branch"
                          info_status={true}
                          info_message="This is the branch list that you have created in the Organisation Settings"
                        />
                      </Grid>
                      <Grid sm={6} md={5} lg={3} xl={3}>
                        <SelectDropdown
                          required
                          class_name="inputField"
                          title="Department"
                          value={editUser?.department}
                          attrName={["editUser", "department"]}
                          value_update={updateMasterState}
                          dropdown_data={department_data}
                          warn_status={editUser.warnDepartment}
                          error_messg="Please select department"
                          info_status={true}
                          info_message="This is the department list that you have created in the Organisation Settings"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    onMouseEnter={() => {
                      setSubSection("personalDetails");
                    }}
                    ref={personalDetails}
                  >
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Personal Details</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <RKTextField
                          required
                          validation_type="name"
                          class_name="inputField"
                          title={"Full Name"}
                          value={editUser?.name}
                          attrName={["editUser", "name"]}
                          value_update={updateMasterState}
                          warn_status={editUser?.warnName}
                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Gender"
                          value={editUser?.gender}
                          attrName={["editUser", "gender", "label_field"]}
                          value_update={updateMasterState}
                          data={gender_data}
                          warn_status={editUser.warnGender}
                          required
                          error_message="Please Choose Gender"

                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <DatePicker
                          class_name="inputField"
                          title="Date of Birth"
                          value={editUser?.dob}
                          attrName={["editUser", "dob"]}
                          value_update={updateMasterState}
                          error_message="Please enter Date of Birth"
                          warn_status={editUser.warnDob}
                          required

                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <RKTextField
                          required
                          class_name="inputField"
                          title={"Mobile Number"}
                          value={editUser?.mobileNumber}
                          attrName={["editUser", "mobileNumber"]}
                          value_update={updateMasterState}
                          warn_status={editUser.warnMobile}
                          error_messg="Mobile Number should be valid"
                          validation_type="numeric"
                          charcterlimit={15}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    onMouseEnter={() => {
                      setSubSection("addressDetails");
                    }}
                    ref={addressDetails}
                  >
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Address Details</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6} md={6} lg={6} xl={6}>
                        <RKTextField
                          class_name="inputField"
                          title={"Street Name"}
                          value={editUser?.building}
                          attrName={["editUser", "building"]}
                          value_update={updateMasterState}
                          warn_status={editUser?.warnStreet}
                          error_messg="Please Enter Street Name"
                          required
                        />
                      </Grid>
                      <Grid sm={6} md={6} lg={6} xl={6}>
                        <RKTextField
                          class_name="inputField"
                          title={"Area Name"}
                          value={editUser?.area}
                          attrName={["editUser", "area"]}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Country"
                          value={editUser?.country}
                          attrName={["editUser", "country", "label_field"]}
                          value_update={updateMasterState}
                          data={countryData}
                          warn_status={editUser?.warnCountry}
                          error_message="Please select country"
                          required
                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="State"
                          value={editUser?.state}
                          attrName={["editUser", "state", "label_field"]}
                          value_update={updateMasterState}
                          data={stateData}
                          warn_status={editUser?.warnState}
                          error_message="Please select state"
                          required
                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="City"
                          value={editUser?.city}
                          attrName={["editUser", "city", "label_field"]}
                          value_update={updateMasterState}
                          data={cityData}
                          warn_status={editUser?.warnCity}
                          error_message="Please select city"
                          required
                        />
                      </Grid>
                      <Grid sm={3} md={3} lg={3} xl={3}>
                        <RKTextField
                          class_name="inputField"
                          title={"Pincode"}
                          value={editUser?.pinCode}
                          attrName={["editUser", "pinCode"]}
                          value_update={updateMasterState}
                          warn_status={editUser.warnPincode}
                          required
                          error_messg="Pincode should be valid"
                          validation_type="numeric"
                          charcterlimit={6}

                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Footer Section */}
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className="submitBtn" disabled={disableSave || userStatus===14}
             
              onClick={detail_form_submit}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default EditCreateUsers;

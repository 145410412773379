import CloseIcon from "@mui/icons-material/Close";
import { Button, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "./AddFeatures.scss";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  api_url: string;
  get_category_api_url: string;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
  refetchData?: Function;
}

const AddFeatures = ({
  open_status,
  attrName,
  value_update,
  api_url,
  get_category_api_url,
  shortcutDisable,
  setShortcutDisable,
  refetchData,
}: Props) => {
  const [rowsCategory, setRowsCategory] = useState<
    Array<{
      _id: Number;
      category: { name: string };
    }>
  >([]);

  const [addCategoryStep, setAddCategoryStep] = useState<any>([
    {
      categoryId: {
        label: "",
        id: "",
      },
      featureDetails: {
        name: "",
        fieldType: "",
        valueType: "",
        unit: "",
        customUnitType: "",
        description: "",
        dropDownOptions: [
          {
            id: "",
            label: "",
          },
        ],
      },
    },
  ]);
  const [addCategoryStepWarn, setAddCategoryStepWarn] = useState<any>([
    {
      categoryId: false,
      featureDetails: {
        name: false,
        fieldType: false,
        valueType: false,
        unit: false,
        customUnitType: false,
        description: false,
        dropDownOptions: [false],
      },
    },
  ]);

  const addFieldType_data = [
    { key: "Input Field", value: "Input Field" },
    { key: "Dropdown List", value: "Dropdown List" },
  ];
  const addValueType_data = [
    { key: "Numeric Only", value: "Numeric Only" },
    { key: "Alphanumeric", value: "Alphanumeric" },
    { key: "Text Only", value: "Text Only" },
  ];
  const unitType_data = [
    { key: "Days", value: "Days" },
    { key: "Months", value: "Months" },
    { key: "Years", value: "Years" },
    { key: "Free Text", value: "Free Text" },
    { key: "Currency", value: "Currency" },
    { key: "Percentage", value: "Percentage" },
  ];
  // get category detail
  useEffect(() => {
    if (open_status) {
      getCatageoryetails();
    }
  }, [open_status]);

  const updateMasterStateArray = (attrName: any, value: any) => {
    const array_data = [...addCategoryStep];
    const array_dataWarn = [...addCategoryStepWarn];
    if (attrName[1] === "category_name") {
      array_data[attrName[0]].categoryId = {
        label: value.label,
        id: value.value,
      };
      array_dataWarn[attrName[0]].categoryId = false;
    } else if (attrName[1] === "feature_name") {
      array_data[attrName[0]].featureDetails.name = value;
      array_dataWarn[attrName[0]].featureDetails.name = false;
    } else if (attrName[1] === "field_type") {
      array_data[attrName[0]].featureDetails.fieldType = value;
      array_data[attrName[0]].featureDetails.unit = "";
      array_data[attrName[0]].featureDetails.valueType = "";
      array_data[attrName[0]].featureDetails.customUnitType = "";

      array_dataWarn[attrName[0]].featureDetails.fieldType = false;
      array_dataWarn[attrName[0]].featureDetails.unit = false;
      array_dataWarn[attrName[0]].featureDetails.valueType = false;
      array_dataWarn[attrName[0]].featureDetails.customUnitType = false;
    } else if (attrName[1] === "value_type") {
      array_data[attrName[0]].featureDetails.valueType = value;
      array_data[attrName[0]].featureDetails.unit = "";

      array_dataWarn[attrName[0]].featureDetails.valueType = false;
      array_dataWarn[attrName[0]].featureDetails.unit = false;
    } else if (attrName[1] === "unit_type") {
      array_data[attrName[0]].featureDetails.unit = value;
      array_dataWarn[attrName[0]].featureDetails.unit = false;
    } else if (attrName[1] === "description") {
      array_data[attrName[0]].featureDetails.description = value;
      array_dataWarn[attrName[0]].featureDetails.description = false;
    } else if (attrName[1] === "custom_unit_type") {
      array_data[attrName[0]].featureDetails.customUnitType = value;
      array_dataWarn[attrName[0]].featureDetails.customUnitType = false;
    } else if (attrName[2] === "Drop down Field value") {
      array_data[attrName[0]].featureDetails.dropDownOptions[
        attrName[1]
      ].label = value;
      array_data[attrName[0]].featureDetails.dropDownOptions[attrName[1]].id =
        value;
      array_dataWarn[attrName[0]].featureDetails.dropDownOptions[attrName[1]] =
        false;
    }

    setAddCategoryStep(array_data);
    setAddCategoryStepWarn(array_dataWarn);
  };

  // get category for feature
  const getCatageoryetails = () => {
    const onSuccess = (res: any) => {
      let arrdata: any = [];
      res.data.data.forEach((e: any) => {
        arrdata.push({ label: e.category.name, value: e._id });
      });
      setRowsCategory(arrdata);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    GROUP_SUPER_TOPUP.getCatageory(get_category_api_url, onSuccess, onError);
  };
  // add category
  const addFeatures = () => {
    let error = false;
    let array_dataWarn = [...addCategoryStepWarn];
    addCategoryStep.map((data: any, index: number) => {
      array_dataWarn[index].categoryId = data.categoryId.id ? false : true;
      array_dataWarn[index].featureDetails.name = data.featureDetails.name
        ? false
        : true;
      array_dataWarn[index].featureDetails.fieldType = data.featureDetails
        .fieldType
        ? false
        : true;
      // array_dataWarn[index].featureDetails.valueType = data.featureDetails.valueType ? false : true;
      // array_dataWarn[index].featureDetails.unit = data.featureDetails.unit ? false : true;
      // array_dataWarn[index].featureDetails.customUnitType = data.featureDetails.customUnitType ? false : true;
      array_dataWarn[index].featureDetails.description = data.featureDetails
        .description
        ? false
        : true;
      if (
        !data.categoryId.id ||
        !data.featureDetails.fieldType ||
        !data.featureDetails.name ||
        !data.featureDetails.description
      ) {
        error = true;
      }
      if (data.featureDetails.fieldType === "Input Field") {
        array_dataWarn[index].featureDetails.valueType = data.featureDetails
          .valueType
          ? false
          : true;
        array_dataWarn[index].featureDetails.unit = false;
        if (!data.featureDetails.valueType) {
          error = true;
        }
        if (data.featureDetails.valueType === "Numeric Only") {
          array_dataWarn[index].featureDetails.unit = data.featureDetails.unit
            ? false
            : true;
          if (!data.featureDetails.unit) {
            error = true;
          }
        }
      } else {
        array_dataWarn[index].featureDetails.valueType = false;
        array_dataWarn[index].featureDetails.unit = false;
        array_dataWarn[index].featureDetails.customUnitType = false;
        array_dataWarn[index].featureDetails.dropDownOptions.map(
          (Indata: any, objectIndex: number) => {
            if (Indata.label === "") {
              array_dataWarn[index].featureDetails.dropDownOptions[
                objectIndex
              ] = true;
              error = true;
            }
          }
        );
      }

      // if (
      //   addCategoryStep[index]?.featureDetails?.fieldType === "Dropdown List"
      // ) {
      //   data.featureDetails.dropDownOptions.map(
      //     (Indata: any, objectIndex: number) => {
      //       if (Indata.label === "") {
      //         error = true;
      //       }
      //     }
      //   );
      // }
    });
    setAddCategoryStepWarn(array_dataWarn);

    const onSuccess = (res: any) => {
      refetchData && refetchData()
      value_update(attrName, false);
      setAddCategoryStep([
        {
          categoryId: { label: "", id: "" },
          featureDetails: {
            name: "",
            fieldType: "",
            valueType: "",
            unit: "",
            customUnitType: "",
            description: "",
            dropDownOptions: [{ id: "", label: "" }],
          },
        },
      ]);
      setAddCategoryStepWarn([
        {
          categoryId: false,
          featureDetails: {
            name: false,
            fieldType: false,
            valueType: false,
            unit: false,
            customUnitType: false,
            description: false,
            dropDownOptions: [false],
          },
        },
      ]);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
     toast.error(`${err.response.data.errors[0].param} ${err.response.data.errors[0].message}`) 
    };

    if (error === false) {
      let newData: any[] = [];
      addCategoryStep?.map(
        (item: any) =>
          (newData = [...newData, { ...item, categoryId: item.categoryId.id }])
      );

      GROUP_SUPER_TOPUP.createFeature(api_url, newData, onSuccess, {}, onError);
    } else {
      toast.error("Please enter Input field's value");
    }
  };

  const removePopup = () => {
    value_update(attrName, false);
    setAddCategoryStep([
      {
        categoryId: {
          label: "",
          id: "",
        },
        featureDetails: {
          name: "",
          fieldType: "",
          valueType: "",
          unit: "",
          description: "",
          customUnitType: "",
          dropDownOptions: [
            {
              id: "",
              label: "",
            },
          ],
        },
      },
    ]);
    setAddCategoryStepWarn([
      {
        categoryId: false,
        featureDetails: {
          name: false,
          fieldType: false,
          valueType: false,
          unit: false,
          customUnitType: false,
          description: false,
          dropDownOptions: [false],
        },
      },
    ]);
    if (setShortcutDisable) {
      setShortcutDisable(false);
    }
  };

  const addInputField = (objIndex: any) => {
    const newCatStep = addCategoryStep.map((item: any, index: any) => {
      let retVal = item;
      if (index === objIndex) {
        retVal = {
          ...item,
          featureDetails: {
            ...item.featureDetails,
            dropDownOptions: [
              ...item.featureDetails.dropDownOptions,
              { id: "", label: "" },
            ],
          },
        };
      }
      return retVal;
    });
    setAddCategoryStep(newCatStep);
  };

  const deleteInputField = (objIndex: number, optionIndex: number) => {
    const newCatStep = addCategoryStep.map((item: any, index: number) => {
      let retVal = item;
      if (index === objIndex) {
        const newOptions = item.featureDetails.dropDownOptions;
        newOptions.splice(optionIndex, 1);

        retVal = {
          ...item,
          featureDetails: {
            ...item.featureDetails,
            dropDownOptions: newOptions,
          },
        };
      }
      return retVal;
    });
    setAddCategoryStep(newCatStep);
  };

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
      >
        <div>
          <Grid container spacing={3} alignItems="center" className="mt-0">
            <Grid xs={8}>
              <h4 className="mb-3">Add New Feature</h4>
              <p>You can add multiple features at a time.</p>
            </Grid>
            <Grid xs={4} className="pt-3 text-right">
              <CloseIcon onClick={removePopup} />
            </Grid>
            <Grid xs={12} className="text-right">
              <Link
                className="greenBtn addfile"
                underline="none"
                onClick={() => {
                  setAddCategoryStep((addCategoryStep: any) => [
                    ...addCategoryStep,
                    {
                      categoryId: { label: "", id: "" },
                      featureDetails: {
                        name: "",
                        fieldType: "",
                        valueType: "",
                        unit: "",
                        customUnitType: "",
                        description: "",
                        dropDownOptions: [{ id: "", label: "" }],
                      },
                    },
                  ]);
                  setAddCategoryStepWarn((addCategoryStepWarn: any) => [
                    ...addCategoryStepWarn,
                    {
                      categoryId: false,
                      featureDetails: {
                        name: false,
                        fieldType: false,
                        valueType: false,
                        unit: false,
                        customUnitType: false,
                        description: false,
                        dropDownOptions: [false],
                      },
                    },
                  ]);
                }}
              >
                Add More
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area lgScroll">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <Typography>
                  {addCategoryStep.map((data: any, index: any) => (
                    <Grid container spacing={3} alignItems="center">
                      <Grid xs={9}>
                        <p className="labelTitle">{index + 1}. Feature</p>
                      </Grid>
                      <Grid xs={3} className="text-right">
                        {index === 0 ? null : (
                          <Link
                            className="redBtn delete ml-0"
                            underline="none"
                            onClick={() => {
                              setAddCategoryStep((addCategoryStep: any) =>
                                addCategoryStep?.filter(
                                  (item: any, i: any) => i !== index
                                )
                              );
                              setAddCategoryStepWarn(
                                (addCategoryStepWarn: any) =>
                                  addCategoryStepWarn?.filter(
                                    (item: any, i: any) => i !== index
                                  )
                              );
                            }}
                          >
                            Remove
                          </Link>
                        )}
                      </Grid>

                      <Grid xs={6}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Select Category"
                          value={data.categoryId?.label}
                          attrName={[index, "category_name"]}
                          value_update={updateMasterStateArray}
                          data={rowsCategory}
                          warn_status={addCategoryStepWarn[index]?.categoryId}
                          error_message="Select Category"
                        />
                      </Grid>
                      <Grid xs={6}>
                        <RKTextField
                          class_name="inputField"
                          title={"Feature Name"}
                          value={data.featureDetails.name}
                          attrName={[index, "feature_name"]}
                          value_update={updateMasterStateArray}
                          warn_status={
                            addCategoryStepWarn[index]?.featureDetails.name
                          }
                          error_messg="Enter Feature Name"
                        />
                      </Grid>
                      <Grid xs={4}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Field Type"
                          value={data.featureDetails.fieldType}
                          attrName={[index, "field_type"]}
                          value_update={updateMasterStateArray}
                          dropdown_data={addFieldType_data}
                          warn_status={
                            addCategoryStepWarn[index]?.featureDetails.fieldType
                          }
                          error_messg="Select Field Type"
                        />
                      </Grid>
                      <Grid xs={4}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Value Type"
                          disabled={
                            !data.featureDetails.fieldType ||
                            data.featureDetails.fieldType === "Dropdown List"
                          }
                          value={data.featureDetails.valueType}
                          attrName={[index, "value_type"]}
                          value_update={updateMasterStateArray}
                          dropdown_data={addValueType_data}
                          warn_status={
                            data.featureDetails.fieldType != "Dropdown List" &&
                            addCategoryStepWarn[index]?.featureDetails.valueType
                          }
                          error_messg="Select Value Type"
                        />
                      </Grid>
                      <Grid xs={4}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Unit Type"
                          disabled={
                            data.featureDetails.valueType !== "Numeric Only"
                          }
                          value={data.featureDetails.unit}
                          attrName={[index, "unit_type"]}
                          value_update={updateMasterStateArray}
                          dropdown_data={unitType_data}
                          warn_status={
                            data.featureDetails.valueType === "Numeric Only" &&
                            addCategoryStepWarn[index]?.featureDetails.unit
                          }
                          error_messg="Select Unit Type"
                        />
                      </Grid>

                      {data.featureDetails.unit === "Free Text" && (
                        <Grid xs={12}>
                          <RKTextField
                            class_name="inputField"
                            title={"Custom Unit Type"}
                            value={data.featureDetails.customUnitType}
                            attrName={[index, "custom_unit_type"]}
                            value_update={updateMasterStateArray}
                            warn_status={
                              addCategoryStepWarn[index]?.featureDetails
                                .customUnitType
                            }
                          />
                        </Grid>
                      )}
                      {data?.featureDetails?.fieldType === "Dropdown List" ? (
                        <Grid xs={12} className="text-right">
                          <button
                            className="greenBtn addfile mb-3"
                            onClick={() => addInputField(index)}
                          >
                            Add DropDown Fields
                          </button>
                          {data.featureDetails.dropDownOptions.map(
                            (inputField: any, indexx: number) => (
                              <div key={index} className="input-container">
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ padding: "0" }}
                                >
                                  <Grid xs={10}>
                                    <RKTextField
                                      class_name="inputField"
                                      title={`Drop down Field ${indexx + 1}`}
                                      value={inputField.label}
                                      attrName={[
                                        index,
                                        indexx,
                                        "Drop down Field value",
                                      ]}
                                      value_update={updateMasterStateArray}
                                      warn_status={
                                        addCategoryStepWarn[index]
                                          ?.featureDetails.dropDownOptions[
                                          indexx
                                        ]
                                      }
                                    />
                                  </Grid>
                                  <Grid xs={2}>
                                    {indexx !== 0 && (
                                      <button
                                        className="redBtn delete"
                                        onClick={() =>
                                          deleteInputField(index, indexx)
                                        }
                                      >
                                        Delete
                                      </button>
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid xs={12}>
                        <TextAreaField
                          class_name="inputField multiline"
                          title={"Brief Description"}
                          value={data.featureDetails.description}
                          attrName={[index, "description"]}
                          value_update={updateMasterStateArray}
                          warn_status={
                            addCategoryStepWarn[index]?.featureDetails
                              .description
                          }
                          alphanumeric={true}
                          specialcharacter={true}
                          error_messg="Enter Description"
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={addFeatures}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </>
  );
};

export default AddFeatures;
